<script>
import {AgGridVue} from "ag-grid-vue";
import PriceDisplayCell from "../grid/PriceDisplayCell";
import ArticleTitleInfoDisplayCell from "../grid/ArticleTitleInfoDisplayCell";
import ArticleActionsCell from "../grid/ArticleActionsCell";
import {mapState} from "vuex";

export default {
  name: "ArticlesGrid",
  data() {
    return {
      gridApi: null,
      columnApi: null,
      rowBuffer: null,
      rowModelType: null,
      loading: true,
      cacheBlockSize: null,
      cacheOverflowSize: null,
      maxConcurrentDatasourceRequests: null,
      infiniteInitialRowCount: null,
      debounceLength: null,
      enableCellTextSelection: true,
      maxBlocksInCache: null,
      datasource: null,
      defaultColDef: {
        floatingFilter: true,
      },
      columnDefs: this.determineColumns()
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AgGridVue, PriceDisplayCell, ArticleTitleInfoDisplayCell, ArticleActionsCell
  },
  created() {
    this.rowBuffer = 0;
    this.rowModelType = 'infinite';
    this.cacheBlockSize = 45;
    this.cacheOverflowSize = 0;
    this.maxConcurrentDatasourceRequests = 2;
    this.infiniteInitialRowCount = 5000;
    this.maxBlocksInCache = 1;
    this.debounceLength = 600; //ms
  },
  computed: {
    ...mapState({
      supplierFilter: (state) => state.articles.filterSupplier,
      maxPricePosition: (state) => state.articles.filterSupplierMaxPosition,
      shouldRefresh: (state) => state.articles.shouldRefresh
    })
  },
  methods: {
    determineColumns() {

      if (this.getCurrentInstance() === 'beverages') {
        return [
          {
            field: "latina_article_id", headerName: "Art#", filter: true, minWidth: 120,
            cellStyle: {'text-align': 'left'},
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ]
            }
          },
          {
            field: "id",
            headerName: "Actions",
            width: 160,
            cellRenderer: 'ArticleActionsCell',
          },
          {
            field: "title",
            minWidth: 300,
            resizable: true,
            headerName: "Article Title",
            cellStyle: {'text-align': 'left'},
            width: 400,
            filter: true,
            cellRenderer: 'ArticleTitleInfoDisplayCell',
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'contains',
                  displayName: 'Contains',
                  numberOfInputs: 1
                },
              ],
              suppressAndOrCondition: true,
            },
          },
          {
            field: "is_disabled",
            minWidth: 100,
            resizable: true,
            headerName: "Disabled",
            cellStyle: {'text-align': 'left'},
            width: 150,
            filter: true,
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'equalsNo',
                  displayName: 'Active',
                  predicate: (_, cellValue) => cellValue === 0,
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'equalsYes',
                  displayName: 'Disabled',
                  predicate: (_, cellValue) => cellValue === 1,
                  numberOfInputs: 0,
                },
              ],
              suppressAndOrCondition: true,
            },
            cellRenderer: (params) => {
              if (params.value === undefined) {
                return '';
              }
              return params.value === 1 ? 'Disabled' : 'Active';
            },
          },
          {
            field: "type",
            minWidth: 180,
            resizable: true,
            headerName: "Type",
            cellStyle: {'text-align': 'left'},
            width: 200,
            filter: true,
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'contains',
                  displayName: 'Contains',
                  numberOfInputs: 1
                },
              ],
              suppressAndOrCondition: true,
            },
          },
          {
            field: "brand_owner",
            minWidth: 180,
            resizable: true,
            headerName: "Brand Owner",
            cellStyle: {'text-align': 'left'},
            width: 250,
            filter: true,
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'contains',
                  displayName: 'Contains',
                  numberOfInputs: 1
                },
              ],
              suppressAndOrCondition: true,
            },
          },
          {
            field: "economical_stock", headerName: "Ec. Stock", minWidth: 100, cellRenderer: (params) => {

              if (params.value === undefined) {
                return '';
              }

              if (params.value === null) {
                return 'N/A';
              }

              return params.value;
            },
            cellStyle: {'text-align': 'right'},
            filter: 'agNumberColumnFilter',
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            },
          },
          {
            field: "is_gift_box", headerName: "GB", minWidth: 80, cellRenderer: (params) => {
              if (params.value === undefined) {
                return '';
              }
              return params.value === 0 ? 'NGB' : 'GB';
            },
            cellStyle: {'text-align': 'right'},
            filter: 'agTextColumnFilter',
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'equalsGb',
                  displayName: 'GB',
                  predicate: (_, cellValue) => cellValue === 'GB',
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'equalsNgb',
                  displayName: 'NGB',
                  predicate: (_, cellValue) => cellValue === 'NGB',
                  numberOfInputs: 0,
                },
              ],
              suppressAndOrCondition: true,
            },
          },
          {
            field: "is_refillable", headerName: "NRF", minWidth: 80,
            filter: 'agTextColumnFilter',
            cellStyle: {'text-align': 'right'},
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'equalsRf',
                  displayName: 'Refillable',
                  predicate: (_, cellValue) => cellValue === 'RF',
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'equalsNrf',
                  displayName: 'Non-Refillable',
                  predicate: (_, cellValue) => cellValue === 'NRF',
                  numberOfInputs: 1,
                },
              ],
              suppressAndOrCondition: true,
            },
          },
          {
            field: "content_count",
            headerName: "Btl/Case",
            minWidth: 110,
            filter: 'agNumberColumnFilter',
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            }
          },
          {
            field: "inner_content_count",
            headerName: "Content",
            minWidth: 120,
            filter: 'agNumberColumnFilter',
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            }
          },
          {
            field: "alcohol_percentage", headerName: "Alc. %", minWidth: 120,
            filter: 'agNumberColumnFilter',
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            }
          },
          {
            field: "has_paper_trail", headerName: "Ppr Trl", minWidth: 120,
            filter: 'agTextColumnFilter',
            cellRenderer: (params) => {
              if (params.value === undefined) {
                return '';
              }
              return params.value === 0 ? 'No' : 'Yes';
            },
            filterParams: {
              
              filterOptions: [
                'empty',
                {
                  displayKey: 'equalsYes',
                  displayName: 'Yes',
                  predicate: (_, cellValue) => cellValue === '1',
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'equalsNo',
                  displayName: 'No',
                  predicate: (_, cellValue) => cellValue === '0',
                  numberOfInputs: 0,
                },
              ],
              suppressAndOrCondition: true,
            }
          },
          {
            field: "sale_price_type", headerName: "Price Type", minWidth: 120,
            filter: 'agTextColumnFilter',
            filterParams: {
              filterOptions: [
                'empty',
                {
                  displayKey: 'equalsA',
                  displayName: 'A',
                  predicate: (_, cellValue) => cellValue === 'A',
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'equalsM',
                  displayName: 'M',
                  predicate: (_, cellValue) => cellValue === 'M',
                  numberOfInputs: 0,
                },
              ],
              suppressAndOrCondition: true,
            }
          },
          {
            field: "sale_price", headerName: "Sale Price", minWidth: 120,
            filter: 'agTextColumnFilter',
            cellRenderer: (params ) => {
              if (params.value) {
                return params.value;
              } else {
                return '-';
              }
            },
            filterParams: {
              filterOptions: [
                'empty',
                {
                  displayKey: 'equalsYes',
                  displayName: 'Yes',
                  predicate: (_, cellValue) => cellValue === '1',
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'equalsNo',
                  displayName: 'No',
                  predicate: (_, cellValue) => cellValue === '0',
                  numberOfInputs: 0,
                },
              ],
              suppressAndOrCondition: true,
            }
          },
          {
            field: "show_on_website", headerName: "WWW?", minWidth: 120,
            filter: 'agTextColumnFilter',
            cellRenderer: (params) => {
              if (params.value === undefined) {
                return '';
              }
              return params.value === 0 ? 'No' : 'Yes';
            },
            filterParams: {
              filterOptions: [
                'empty',
                {
                  displayKey: 'equalsYes',
                  displayName: 'Yes',
                  predicate: (_, cellValue) => cellValue === '1',
                  numberOfInputs: 0,
                },
                {
                  displayKey: 'equalsNo',
                  displayName: 'No',
                  predicate: (_, cellValue) => cellValue === '0',
                  numberOfInputs: 0,
                },
              ],
              suppressAndOrCondition: true,
            }
          },
          {
            field: "owner_user_id", headerName: "Owner", minWidth: 160,
            filter: true,
            cellRenderer: (params) => {
              if (! params.value || ! params.data.owner_user) {
                return '';
              }
              return params.data.owner_user.name;
            },
            filterParams: {
              buttons: [
                'apply',
                'reset'
              ],
              filterOptions: [
                'empty',
                {
                  displayKey: 'contains',
                  displayName: 'Contains',
                  numberOfInputs: 1
                },
              ],
              suppressAndOrCondition: true,
            },
          }
        ];
      } else {
        return [
          {
            field: "latina_article_id", headerName: "Art#", filter: true, minWidth: 120,
            cellStyle: {'text-align': 'left'}
          },
          {
            field: "title",
            minWidth: 300,
            resizable: true,
            headerName: "Article Title",
            cellStyle: {'text-align': 'left'},
            width: 400,
            filter: true,
            cellRenderer: 'ArticleTitleInfoDisplayCell',
            filterParams: {
              filterOptions: [
                'empty',
                {
                  displayKey: 'contains',
                  displayName: 'Contains',
                  numberOfInputs: 1
                },
              ],
              suppressAndOrCondition: true,
            },
          },
          {
            field: "economical_stock", headerName: "Ec. Stock", minWidth: 100, cellRenderer: (params) => {

              if (params.value === undefined) {
                return '';
              }

              if (params.value === null) {
                return 'N/A';
              }

              return params.value;
            },
            cellStyle: {'text-align': 'right'},
            filter: 'agNumberColumnFilter',
            filterParams: {
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            },
          },
          {
            field: "type",
            headerName: "Type",
            resizable: true,
            minWidth: 160,
            filter: true,
            cellStyle: {'text-align': 'left'},
            filterParams: {
              filterOptions: [
                'empty',
                {
                  displayKey: 'contains',
                  displayName: 'Contains',
                  numberOfInputs: 1
                },
              ],
              suppressAndOrCondition: true
            }
          },
          {
            field: "inner_content_count",
            headerName: "Content",
            minWidth: 120,
            cellStyle: {'text-align': 'left'},
            filter: 'agNumberColumnFilter',
            filterParams: {
              filterOptions: [
                'equals',
                'greaterThan',
                'lessThan'
              ],
              suppressAndOrCondition: true
            }
          }
        ];
      }
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    async fetchArticles(params) {
      this.loading =  true;
      if (this.supplierFilter) {
        params.supplier = this.supplierFilter;
        params.maxPricePosition = this.maxPricePosition;
      } else {
        params.supplier = null;
        params.maxPricePosition = null;
      }

      return await this.$store.dispatch('articles/fetchArticles', params);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.sizeToFit();

      const dataSource = {
        rowCount: undefined,
        getRows: (params) => {
          this.fetchArticles(params).then((response) => {
            params.successCallback(response.data, response.lastRow);
            this.loading =  false;
          }, () => {
          });
        }
      }

      this.gridApi.setDatasource(dataSource);
    }
  },
  watch: {
    supplierFilter: function() {
      this.gridApi.refreshInfiniteCache();
    },
    shouldRefresh: function() {
      this.gridApi.refreshInfiniteCache();
    }
  }
};
</script>

<template>
  <b-overlay :show="loading" rounded="sm">
  <ag-grid-vue style=" width: 100%; min-height: 730px; height: 77vh"
               class="ag-theme-alpine"
               @grid-ready="onGridReady"
               :columnDefs="columnDefs"
               :enableCellTextSelection="enableCellTextSelection"
               :rowBuffer="rowBuffer"
               :rowModelType="rowModelType"
               :defaultColDef="defaultColDef"
               :blockLoadDebounceMillis="debounceLength"
               :cacheBlockSize="cacheBlockSize"
               :cacheOverflowSize="cacheOverflowSize"
               :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
               :infiniteInitialRowCount="infiniteInitialRowCount"
               :maxBlocksInCache="maxBlocksInCache">
  </ag-grid-vue>
  </b-overlay>
</template>


<style lang="scss">

.align-middle {
  display: flex;
  align-items: center;
}
</style>